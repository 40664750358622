import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CreateWithdrawalResponse } from '../common/models/createWithdrawlResponse.model';
import { Transaction } from '../feature/dashboard/dashboard/transactions/transaction.model';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  private apiUrl = `${environment.apiUrl}/finance`;

  constructor(private readonly http: HttpClient) {}

  registerDepositing(
    transactionHash: string,
    from: string,
    amount: string,
    currencyId: string,
  ): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/register-depositing`, {
      data: {
        currencyId,
        amount: Number(amount),
        walletAddress: from,
        transactionHash,
      },
    });
  }

  createWithdrawal(
    from: string,
    amount: number,
    currencyId: string,
  ): Observable<CreateWithdrawalResponse> {
    return this.http.post<CreateWithdrawalResponse>(
      `${this.apiUrl}/create-withdrawal`,
      {
        data: {
          currencyId,
          amount,
          walletAddress: from,
        },
      },
    );
  }

  getTransactions(
    page: number,
    size: number,
  ): Observable<{
    transactions: Transaction[];
    total: number;
  }> {
    return this.http.post<{
      transactions: Transaction[];
      total: number;
    }>(`${this.apiUrl}/get-transactions`, {
      data: {
        page,
        size,
      },
    });
  }
}
